<template>
  <div class="avatar">
    <VSkeletonLoader
      v-if="loadingImg"
      :width="avatarSize"
      :height="avatarSize"
      type="avatar"
    />
    <VAvatar
      :size="avatarSize"
      :class="{'cursor--pointer' : clickable && img, 'hide-avatar' : loadingImg}"
    >
      <VImg
        v-if="img"
        :src="img"
        alt="avatar"
        @click="showBigAvatar"
        @error="loadImg"
        @load="loadImg"
      />
      <div
        v-else
        class="avatar__text"
        :style="{color : avatarColor.textColor, background : avatarColor.backgroundColor, 'font-size' : textSize}"
      >
        <p
          v-if="avatarText"
          class="mb-0"
        >
          {{ avatarText }}
        </p>
        <VImg
          v-else
          :src="anonymAvatar"
          alt="avatar"
        />
      </div>
    </VAvatar>
    <DialogWrapper
      v-model="avatarDialog"
      max-width="800"
    >
      <VImg
        :lazy-src="img"
        :src="img"
        aspect-ratio="1.33333"
      >
        <template #placeholder>
          <VRow
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <VProgressCircular
              indeterminate
              color="primary"
            />
          </VRow>
        </template>
      </VImg>
    </DialogWrapper>
  </div>
</template>

<script>
import AnonymAvatar from '@/assets/images/nouser2.svg';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';

const sizes = ['xs', 'sm', 'md', 'lg', 'xl'];
const avatarColors = [
  { textColor: '#00A0F2', backgroundColor: '#D9F5FF' },
  { textColor: '#00A345', backgroundColor: '#D4F8E7' },
  { textColor: '#FF3E00', backgroundColor: '#FFEAE3' },
  { textColor: '#FF00D7', backgroundColor: '#FFE2FC' },
  { textColor: '#601EF3', backgroundColor: '#EDE5FE' },
  { textColor: '#EF323F', backgroundColor: '#FFE2E2' },
  { textColor: '#FFB500', backgroundColor: '#FFF6DC' },
];

export default {
  name: 'NewSAvatar',
  components: { DialogWrapper },
  props: {
    img: {
      type: String,
      default: '',
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'sm',
      validator(val) {
        return sizes.includes(val);
      },
    },
  },
  data: () => ({
    avatarDialog: false,
    avatarColor: avatarColors[Math.floor(Math.random() * avatarColors.length)],
    loadingImg: false,
  }),
  computed: {
    avatarText() {
      if (this.name) {
        const nameParts = this.name.split(' ');
        if (nameParts[0]) {
          return `${nameParts[0][0].toUpperCase()}${nameParts[1] ? nameParts[1][0].toUpperCase() : ''}`;
        }
      }
      return '';
    },
    avatarSize() {
      switch (this.size) {
        case 'xs':
          return 24;
        case 'md':
          return 44;
        case 'lg':
          return 72;
        case 'xl':
          return 96;
        case 'sm':
        default:
          return 36;
      }
    },
    textSize() {
      switch (this.size) {
        case 'xs':
          return '12px';
        case 'md':
          return '20px';
        case 'lg':
          return '24px';
        case 'xl':
          return '32px';
        case 'sm':
        default:
          return '14px';
      }
    },
    anonymAvatar() {
      return AnonymAvatar;
    },
  },
  created() {
    if (this.img) {
      this.loadingImg = true;
    }
  },
  methods: {
    showBigAvatar(evt) {
      if (this.clickable && this.img) {
        evt.stopPropagation();
        this.avatarDialog = true;
      }
    },
    loadImg() {
      this.loadingImg = false;
    },
  },
};
</script>

<style scoped lang="scss">
/* stylelint-ignore no-empty-source */
.avatar {
  position: relative;
  .avatar__text {
    width: 100%;
    height: 100%;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin-bottom: 0;
    }
  }
  .hide-avatar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
</style>
<style>
.avatar .v-skeleton-loader__avatar {
  height: 100%;
  width: 100%;
}
</style>
